<template>
  <div class="tabulation">
    <el-card>
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width:57%">
          <el-input
            class="hunt"
            v-model="content"
            placeholder="请输入工会名称"
            size="large"
            @input="power"
          />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="minutely"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <div>
          <el-button style="margin-left: 10px" type="primary" size="large" @click="dialogFormVisible = true"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <el-table
        :data="
          filterTableData.slice(
            (currentPage - 1) * pagesize,
            currentPage * pagesize
          )
        "
        style="width: 100%"
        @cell-click="cat"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
      >
        <el-table-column fixed prop="unionName" label="工会名称" width="60" />
        <el-table-column
          label="类型"
          prop="unionType"
          :formatter="formatSex"
          width="50"
          align="center"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="80"
          :formatter="quantum"
          align="center"
        />
        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template #default="scope">
            <el-button
              size="default"
              type="success"
              @click="handleEdit(scope.$index, scope.row)"
              class="green"
            >
              <el-icon class="cancel">
                <Setting />
              </el-icon>
              管 理</el-button
            >
            <el-button
              size="default"
              type="primary"
              @click="copyreader"
              class="bulur"
            >
              <el-icon class="cancel">
                <EditPen />
              </el-icon>
              编 辑</el-button
            >
            <el-button
              size="default"
              type="danger"
              @click="expurgate"
              class="red"
            >
              <el-icon class="cancel">
                <Delete />
              </el-icon>
              删 除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 100]"
        :page-size="pagesize"
        :total="filterTableData.length"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 增加弹窗 -->
    <el-dialog v-model="dialogFormVisible" title="工会添加" width="30%" center>
      <div class="labor">
        <div class="designation">工会名称 ：</div>
        <div><el-input v-model="price" placeholder="请输入工会名称" /></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="ensure()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="工会删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除此工会吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="remove = false">取消</el-button>
          <el-button type="primary" @click="omit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑工会按钮 -->
    <el-dialog v-model="redact" title="工会编辑" width="30%" center>
      <div class="labors">
        <div class="labor">
          <div class="designation">编辑工会名称 ：</div>
          <div><el-input v-model="prices" placeholder="请输入工会名称" /></div>
        </div>
        <div class="labor">
          <div class="designation">是否开启权限 ：</div>
          <div>
            <el-select
              v-model="tacitly"
              class="m-2"
              placeholder="是否开启权限"
              size="default"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="redact = false">取消</el-button>
          <el-button type="primary" @click="compile">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// Plus,
import { Delete, EditPen, Setting, Search,Plus } from "@element-plus/icons-vue";
import { computed, ref, onMounted } from "vue";
import { Labor, Increase, Cancel, Modify, Message } from "../../utils/api";
import { ElMessage } from "element-plus";
import qs from "qs";
import { useRouter } from "vue-router";
export default {
  setup() {
    // 搜索
    let power=(value)=>{
      if(value==''){
        union()
      }
    }
    let content = ref('')
    let minutely = ()=>{
      console.log(content.value)
      const data = {
        page: 1,
        limit: aggrandize.value,
        unionName:content.value
      };
      Labor(qs.stringify(data)).then((res) => {
        tableData.value = res.data.data.page.list;
        console.log(tableData.value);
        console.log(res);
      });
    }
    // 
    let quantum = (row, column, cellValue) => {
      return cellValue.slice(0, 11);
    };
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "工会";
      } else if (cellValue === 2) {
        return "部门";
      }
    };
    const router = useRouter();
    // 管理跳转
    const handleEdit = () => {
      router.push({ name: "branch", params: { userId: notable } });
    };
    let prices = ref("");
    // 当前页数
    let currentPage = ref(1);
    const handleCurrentChange = (Page) => {
      aggrandize.value += 10;
      currentPage.value = Page;
      console.log(aggrandize.value);
      union();
    };
    const handleSizeChange = (size) => {
      pagesize.value = size;
    };
    // 每页显示几条数据
    let pagesize = ref(8);
    // 工会类型选择器
    // 选择器
    let tacitly = ref("");

    const options = [
      {
        value: "1",
        label: "开启",
      },
      {
        value: "2",
        label: "关闭",
      },
    ];

    let notable = ref(null);
    let unionType = ref(null);
    const cat = (row) => {
      notable = row.unionId;
      unionType = row.unionType;
      sessionStorage.setItem("userId", notable);
      sessionStorage.setItem("unionType", unionType);
      console.log(notable);
    };
    // 编辑按钮弹窗
    const compile = () => {
      redact.value = false;
      console.log(tacitly.value);
      const data = {
        unionId: notable,
        newUnionName: prices.value,
        isEnable: tacitly.value,
      };
      Modify(qs.stringify(data)).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          ElMessage({
            message: "编辑成功",
            type: "success",
            center: true,
          });
        }
        union();
      });
    };
    // 是否开启状态
    const copyreader = () => {
      redact.value = true;
      const data = {
        unionId: notable,
      };
      Message(qs.stringify(data)).then((res) => {
        // console.log(res);
        if (res.data.data.isEnable == 1) {
          tacitly.value = "1";
        } else if (res.data.data.isEnable == 2) {
          tacitly.value = "2";
        }
        prices.value = res.data.data.unionName;
      });
      console.log(prices);
    };
    // 增加弹窗
    let price = ref("");
    let dialogFormVisible = ref(false);
    let remove = ref(false);
    let redact = ref(false);
    let tableData = ref([]);
    // 删除按钮
    const omit = () => {
      Cancel({
        unionId: notable,
      }).then((res) => {
        if (res.data.code == 0) {
          ElMessage({
            message: "删除成功",
            type: "success",
            center: true,
          });
        }
        minutely();
      });
      remove.value = false;
      console.log(notable);
    };
    const expurgate = () => {
      remove.value = true;
      console.log("123");
    };
    // 添加工会
    const ensure = () => {
      dialogFormVisible.value = false;
      const data = {
        unionName: price.value,
      };
      if (price.value == "") {
        ElMessage({
          message: "内容不能为空",
          type: "error",
          center: true,
        });
      } else {
        Increase(qs.stringify(data)).then((res) => {
          if (res.data.code == 0) {
            ElMessage({
              message: "添加成功",
              type: "success",
              center: true,
            });
          }
          price.value = ""
          union();
        });
      }
      console.log(price.value);
    };
    // 协会列表接口
    const aggrandize = ref(50);
    const union = () => {
      const data = {
        page: 1,
        limit: aggrandize.value,
      };
      Labor(qs.stringify(data)).then((res) => {
        tableData.value = res.data.data.page.list;
        console.log(tableData.value);
        console.log(res);
      });
    };
    onMounted(() => {
      union();
    });
    const search = ref("");
    const filterTableData = computed(() =>
      tableData.value.filter(
        (data) =>
          !search.value ||
          data.unionName.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    return {
      quantum,
      formatSex,
      // handleClick,
      filterTableData,
      search,
      union,
      dialogFormVisible,
      ensure,
      price,
      remove,
      expurgate,
      omit,
      redact,
      copyreader,
      compile,
      cat,
      notable,
      tacitly,
      options,
      pagesize,
      currentPage,
      handleSizeChange,
      handleCurrentChange,
      prices,
      handleEdit,
      unionType,
      content,
      minutely,
      power
    };
  },
  components: {
    Delete,
    EditPen,
    Plus,
    Setting,
    Search,
  },
};
</script>
<style lang="scss" scoped>
.cancelgu{
  margin-right: 6px;
}
::v-deep .el-table .el-table__cell{
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}
.hunt {
  width: 36.5%;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.green {
  color: #67c23a;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
  --el-button-active-bg-color:none;
}
.green:hover {
  color: #b3e19d;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
  --el-button-active-bg-color:none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
  --el-button-active-bg-color:none;
}
.bulur:hover {
  color: #a0cfff;
}
.el-scrollbar__thumb {
  background-color: #ffffff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
// .el-table__body-wrapper{
//   height: 510px;
// }
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-card__body {
  padding: 0;
}
.labors {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-pagination {
  margin-bottom: 8px;
  margin-top: 8px;
}
::v-deep .el-card.is-always-shadow {
  width: 95%;
}
::v-deep .example-pagination-block + .example-pagination-block {
  margin-top: 10px;
}
::v-deep .example-pagination-block .example-demonstration {
  margin-bottom: 16px;
}
// 删除工会
.sure {
  font-size: 16px;
}
// 添加工会弹出层
.designation {
  font-size: 15px;
  font-weight: bold;
}
.dialog-footer {
  display: flex;
  justify-content: space-evenly;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
//
.cancel {
  margin-right: 5px;
}
::v-deep .el-table__header {
  width: 100% !important;
  margin-bottom: 0;
  margin-top: 0;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
::v-deep .el-table__body {
  width: 100% !important;
  margin-bottom: 0;
  margin-top: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.tianjia {
  margin-left: 20px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
